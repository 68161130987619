import {CheckCircleFilled, ClockCircleOutlined, SyncOutlined, WarningFilled, WarningOutlined} from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { Tag } from "antd";

export const StatusLabels = ({ value, size = '' }) => {
    const _t = useTranslate();
    const label = _t(`orders.fields.statusValues.${value}`);

    if (value === "submitted")
        return (<Tag icon={<ClockCircleOutlined />} color="default" className={`font-bold ${size}`}>{label}</Tag>)

    if (value === "ongoing")
        return (<Tag icon={<SyncOutlined spin />} color="processing" className={`font-bold ${size}`}>{label}</Tag>)

    if (value === "completed")
        return (<Tag icon={<CheckCircleFilled />} color="success" className={`font-bold ${size}`}>{label}</Tag>)

    if (value === "sav")
        return (<Tag icon={<WarningFilled />} color="warning" className={`font-bold ${size}`}>{label}</Tag>)

    return (<Tag>{label}</Tag>)
};

