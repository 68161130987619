import {useState} from "react";
import {Field, FieldDataTypes} from "./Field";
import {Button, Checkbox, Flex, Form, Input, InputNumber, Popconfirm, Select} from "antd";
import {generateRandomString} from "../../../shared/utils";
import {DeleteOutlined} from "@ant-design/icons";


const CalculatorOptions = [
    'Audi Multitronic',
    'Bosch',
    'Campi',
    'Caterpillar',
    'Chrysler',
    'Delco',
    'Delphi',
    'Denso',
    'EFI',
    'GMPT',
    'Hella',
    'Hitachi',
    'Kefico',
    'Keihin',
    'Lucas',
    'Marelli',
    'Mitsubishi',
    'Moroic',
    'Motorola/Cummins',
    'Phoenix',
    'SISU',
    'Saab',
    'Sagem',
    'Shindengen',
    'Siemens/Continental',
    'TRW',
    'Temic',
    'Transtron',
    'VDO',
    'Valeo',
    'Visteon'
];

const MethodOptions = [
    {
        label: 'Alientech',
        options: [
            {
                title: 'Alientech Kess3 OBD',
                value: 'Alientech Kess3 OBD'
            },
            {
                title: 'Alientech Kess3 Bench',
                value: 'Alientech Kess3 Bench'
            },
            {
                title: 'Alientech Kess3 Boot',
                value: 'Alientech Kess3 Boot'
            }
        ]
    },
    {
        label: 'Autotuner',
        options: [
            {
                title: 'Autotuner Bench',
                value: 'Autotuner Bench'
            },
            {
                title: 'Autotuner Boot',
                value: 'Autotuner Boot'
            },
            {
                title: 'Autotuner OBD',
                value: 'Autotuner OBD'
            }
        ]
    },
    {
        label: 'bFlash',
        options: [
            {
                title: 'bFlash Bench',
                value: 'bFlash Bench'
            },
            {
                title: 'bFlash Boot',
                value: 'bFlash Boot'
            },
            {
                title: 'bFlash OBD',
                value: 'bFlash OBD'
            }
        ]
    },
    {
        label: 'CMD',
        options: [
            {
                title: 'CMD Bench',
                value: 'CMD Bench'
            },
            {
                title: 'CMD Boot Tricore',
                value: 'CMD Boot Tricore'
            },
            {
                title: 'CMD Flash',
                value: 'CMD Flash'
            }
        ]
    },
    {
        label: 'Dimsport',
        options: [
            {
                title: 'Dimsport New Genius',
                value: 'Dimsport New Genius'
            },
            {
                title: 'Dimsport Trasdata',
                value: 'Dimsport Trasdata'
            }
        ]
    },
    {
        label: 'MagicMotorsport',
        options: [
            {
                title: 'Flex Bench',
                value: 'Flex Bench'
            },
            {
                title: 'Flex Boot',
                value: 'Flex Boot'
            },
            {
                title: 'Flex OBD',
                value: 'Flex OBD'
            },
            {
                title: 'X17 Boot',
                value: 'X17 Boot'
            },
            {
                title: 'X17 OBD',
                value: 'X17 OBD'
            }
        ]
    },
    {
        label: 'PCM Flash',
        options: [
            {
                title: 'PCM Flash',
                value: 'PCM Flash'
            }
        ]
    },
    {
        label: 'Others',
        options: [
            {
                title: 'Others',
                value: 'Others'
            }
        ]
    }
];

export class InputField implements Field {
    id = generateRandomString(10);
    name = 'InputField' + generateRandomString(4);
    description = 'The basic input field';
    label = 'Input';
    dataType = FieldDataTypes.STRING;
    required = false;
    defaultValue = '';
    formTag = 'InputField';
    parent = null;

    constructor(isTemplate = false) {
        if (isTemplate) {
            this.name = 'Input';
        }
    }

    render = () => (
        <Form.Item
            label={this.label}
            rules={[{required: this.required}]}
            labelCol={{span: 8}}
            name={this.name}
            initialValue={this.defaultValue}
        >
            {this.name == 'calculator' && (
                <Select
                    placeholder="Select a option and change input text above"
                    allowClear
                >
                    {CalculatorOptions.map((opt) => (
                        <Select.Option key={opt} value={opt}>{opt}</Select.Option>
                    ))}
                </Select>
            )}
            {this.name == 'method' && (
                <Select
                    allowClear
                    options={MethodOptions}
                >
                </Select>
            )}
            {this.name != 'calculator' && this.name != 'method' && (
                <span>
                    {this.dataType === FieldDataTypes.NUMBER
                        ? <InputNumber defaultValue={this.defaultValue}/>
                        : <Input defaultValue={this.defaultValue}/>
                    }
                </span>
            )}

        </Form.Item>
    );

    renderEditor = (field: Field, selectedComponent, setSelectedComponent, deleteComponent) => {
        return <InputFieldEditor field={field} deleteComponent={deleteComponent}/>;
    };

    renderPropertiesEditor = (field: Field, updateField: any) => {
        return <InputFieldPropsEditor inputField={field} setInputField={updateField}/>;
    }

    static fromJSON = (json: any, parent = null) => {
        console.log('InputField from JSON', json);
        const input = new InputField();
        input.id = json.id;
        input.label = json.label;
        input.name = json.name;
        input.description = json.description;
        input.dataType = json.dataType;
        input.required = json.required;
        input.defaultValue = json.defaultValue;
        input.formTag = json.formTag;
        input.parent = parent;
        //console.log('InputFieldz', input.name);
        return input;
    }

    toJSON = () => {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            label: this.label,
            dataType: this.dataType,
            required: this.required,
            defaultValue: this.defaultValue,
            formTag: this.formTag,
            parent: this.parent?.id,
        };
    }

}

export const InputFieldEditor = ({field, deleteComponent}) => {
    console.log('Delete Component', deleteComponent);
    return (
        <Flex justify="space-between">
            <Form.Item
                label={field.label}
                name={field.name}
                className="w-full"
                rules={[{required: field.required}]}
            >
                {field.dataType === FieldDataTypes.NUMBER
                    ? <InputNumber required={field.required}/>
                    : <Input required={field.required}/>
                }
            </Form.Item>
            <Popconfirm
                title="Delete the item"
                description="Are you sure to delete this item?"
                onConfirm={() => deleteComponent(field)}
                okText="Yes"
                cancelText="No"
            >
                <Button size='small' danger icon={<DeleteOutlined/>}/>
            </Popconfirm>
        </Flex>
    )
}

export const InputFieldPropsEditor = ({inputField, setInputField}: { inputField: Field, setInputField: any }) => {

    const [required, setRequired] = useState(inputField.required);

    const handleInputChange = (prop) => (e) => {
        //const newVal = { ...inputField, [prop]: e.target.value };
        inputField[prop] = e.target.value;
        setInputField(inputField);
    };

    const handleSelectChange = (prop) => (value) => {
        //const newVal = { ...inputField, [prop]: value };
        inputField[prop] = value;
        setInputField(newVal);
    };

    const handleCheckboxChange = (prop) => (e) => {
        //const newVal = { ...inputField, [prop]: e.target.checked };
        setRequired(e.target.checked);
        inputField[prop] = e.target.checked;
        setInputField(inputField);
    };

    return (
        <Form layout="vertical">
            <Form.Item label="Name">
                <Input value={inputField.name} onChange={handleInputChange('name')}/>
            </Form.Item>
            <Form.Item label="Description">
                <Input value={inputField.description} onChange={handleInputChange('description')}/>
            </Form.Item>
            <Form.Item label="Label">
                <Input value={inputField.label} onChange={handleInputChange('label')}/>
            </Form.Item>
            <Form.Item label="Data Type">
                <Select value={inputField.dataType} onChange={handleSelectChange('dataType')}>
                    <Select.Option value={FieldDataTypes.STRING}>String</Select.Option>
                    <Select.Option value={FieldDataTypes.NUMBER}>Number</Select.Option>
                    {/* Add more Options for other data types as needed */}
                </Select>
            </Form.Item>
            <Form.Item label="Required">
                <Checkbox checked={required} onChange={handleCheckboxChange('required')}/>
            </Form.Item>
            <Form.Item label="Default Value">
                <Input value={inputField.defaultValue} onChange={handleInputChange('defaultValue')}/>
            </Form.Item>
        </Form>
    );
};
