import React, { useEffect, useState } from "react";
import {
    IResourceComponentsProps,
    BaseRecord,
    useTranslate,
    useGetIdentity,
    useExport,
    CrudFilters,
    CrudFilter,
    useNotification,
} from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ShowButton,
    DateField,
    TextField,
    ExportButton,
    useSelect,
    getDefaultSortOrder,
    FilterDropdown,
    DeleteButton,
} from "@refinedev/antd";
import { Table, Space, Tag, Button, Breadcrumb, Form, Input, DatePicker, FormProps, Card, Select, message } from "antd";
import { dateSorter, flattenData } from "../../shared/utils";
import { StatusLabels } from "./components/statusLabels";
import { UserRole } from "../../models/role";
import { EyeOutlined, MessageOutlined, SearchOutlined } from "@ant-design/icons";
import { CustomerBackButton } from "./components/customerBackButton";
import { CustomerBreadcrumb } from "./components/customerBreadcrumb";
import dayjs from 'dayjs';
import { useAdmin } from "../../hooks/auth";
import useOrdersExport from "./hooks";
import { ListFilter } from "./components/listFilter";


export const SavList: React.FC<IResourceComponentsProps> = () => {
    const _t = useTranslate();
    const { data: user } = useGetIdentity();
    const isAdmin = useAdmin();
    const account = user?.account;
    const [orders, setOrders] = useState([] as BaseRecord[]);
    const [fieldLabels, setFieldLabels] = useState({} as any);
    const [globalFilters, setGlobalFilters] = useState([] as CrudFilter[]);
    const [exportQueryParams, setExportQueryParams] = useState("");
    const { open } = useNotification();
    const [messageApi, contextHolder] = message.useMessage();
    const { tableProps, tableQueryResult, searchFormProps, sorters } = useTable({
        resource: "orders",
        syncWithLocation: true,
        sorters: {
            mode: "server",
            initial: [
                {
                    field: "id",
                    order: "desc",
                },
                {
                    field: "createdAt",
                    order: "desc",
                }
            ],
        },
        filters: {
            initial: [
                {
                    field: "status",
                    operator: "eq",
                    value: "sav",
                }
            ]
        },
        pagination: {
            mode: "server",
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q, vin, billingMode, status, account, createdAt } = params;
            console.log("Search params", params);

            filters.push(
                {
                    field: "account",
                    operator: "eq",
                    value: account,
                },
                {
                    field: "status",
                    operator: "eq",
                    value: status,
                },
                {
                    field: "vin",
                    operator: "eq",
                    value: vin,
                },
                {
                    field: "billingMode",
                    operator: "eq",
                    value: billingMode,
                },
                {
                    field: "createdAt",
                    operator: "gte",
                    value: createdAt ? createdAt[0].format("YYYY-MM-DD") : undefined,
                },
                {
                    field: "createdAt",
                    operator: "lte",
                    value: createdAt ? createdAt[1].format("YYYY-MM-DD") : undefined,
                },
            );

            setGlobalFilters(filters);
            console.log("Filters", filters);

            return filters;
        },
    });

    useEffect(() => {
        console.log("Global filters", globalFilters);
        // Construct query params based on global filters to be used for export
        // Query looks like `?createdAt_gte=2024-04-26&createdAt_lte=2024-05-23&status=completed`;
        const queryParams =
            Object.entries(globalFilters)
                .filter(([key, value]) => value.value)
                .map(([key, value]) => {
                    if (value.field === "createdAt")
                        return `${encodeURIComponent(value.field)}_${value.operator}=${encodeURIComponent(value.value)}`
                    else
                        return `${encodeURIComponent(value.field)}=${encodeURIComponent(value.value)}`
                })
                .join('&');
        console.log("Query params", queryParams);
        setExportQueryParams(queryParams);
    }), [globalFilters];


    useEffect(() => {
        if (isAdmin) {
            const intervalId = setInterval(() => {
                messageApi.open({
                    type: 'loading',
                    content: 'Action in progress..',
                    duration: 100,
                });
                tableQueryResult.refetch();
            }, 10000); // Fetches every 30 seconds

            // Clear interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [isAdmin]); // Empty dependency array means this effect runs once on mount and clean up on unmount

    // Close message on successful data refresh
    tableQueryResult.isSuccess && messageApi.destroy();


    const { triggerExport: triggerExport, isLoading: exportLoading } = useOrdersExport({
        queryParams: exportQueryParams,
        mapData: (item) => {
            const order = mapOrders([item])[0];
            console.log("Item", order);
            const products = order.products
                .map(product => product.name ?? "")
                .join(", ");
            console.log("Products", products);

            return {
                id: order.id,
                date: dayjs(order.createdAt).format("DD/MM/YYYY HH:mm"),
                marque: order.make,
                modele: order.model,
                vin: order.vin,
                produits: products,
                compte: order.account?.name ?? "",
            };
        }
    });

    const mapOrders = (data: any) => {
        return data.map((order: any) => {
            //console.log("Order data", order);
            let formFieldValues: any = {};
            if (order.payload !== undefined && order.payload !== null && order.payload.trim() !== '') {
                //console.log("Order payload", order.payload)
                const formData = flattenData(JSON.parse(order.payload));
                //console.log("Flattend data", formData);
                for (let i = 0; i < formData.length; i++) {
                    if (formData[i].value !== undefined)
                        formFieldValues[formData[i].name] = formData[i].value;
                }
                const labels = formData.reduce((acc: any, item: any) => {
                    acc[item.name] = item.label;
                    return acc;
                });
                setFieldLabels(labels);
                //console.log(formFieldValues);
            }
            const result = {
                ...order,
                ...formFieldValues
            };
            //console.log(result);
            return result;
        });
    }

    useEffect(() => {
        if (tableQueryResult?.data?.data) {
            const data = tableQueryResult?.data?.data;
            //console.log("JSON data", data);
            let os = mapOrders(data);
            os.sort((a, b) => (b.unreadMessages?.length || 0) - (a.unreadMessages?.length || 0));
            setOrders(os);
        }
    }, [tableQueryResult.data]);

    return (
        <>
            {contextHolder}
            <List
                canCreate={false}
                title={<>
                    {user?.role?.id == UserRole.user && (
                        <CustomerBackButton />
                    )}
                    {_t("orders.titles.list")}
                </>}
                breadcrumb={
                    <>
                        {user?.role?.id == UserRole.user && (
                            <CustomerBreadcrumb title={_t('actions.list')} />
                        )}
                    </>
                }
                resource="orders"
                headerButtons={
                    isAdmin && (
                        <ExportButton onClick={triggerExport} loading={exportLoading} />
                    )
                }
            >

                <Card size="small" className="mb-4 border-0 bg-transparent hidden">
                    <ListFilter formProps={searchFormProps} isAdmin={isAdmin} />
                </Card>

                <Table {...tableProps} rowKey="id" dataSource={orders}>

                    <Table.Column
                        dataIndex="createdAt"
                        title={_t("orders.fields.createdAt")}
                        render={(value: any) => <DateField value={value} format="DD/MM/YYYY HH:mm" />}
                        width={180}
                    //defaultSortOrder={getDefaultSortOrder("createdAt", sorters)}
                    //sorter={{ multiple: 2 }}
                    />
                    <Table.Column
                        dataIndex="make"
                        title={fieldLabels["make"]}
                    />
                    <Table.Column
                        dataIndex="model"
                        title={fieldLabels["model"]}
                    />
                    <Table.Column
                        dataIndex="vin"
                        title={fieldLabels["vin"]}
                    />
                    <Table.Column
                        dataIndex="products"
                        title={_t("orders.fields.products")}
                        render={(value: any) => value?.map((product: any) => product.name).join(", ")}
                    />
                    <Table.Column
                        dataIndex={["account", "name"]}
                        title={_t("orders.fields.account")}
                    />
                    <Table.Column
                        dataIndex="status"
                        title={_t("orders.fields.status")}
                        render={(value: any, record: any) =>
                            <div>
                                {record?.unreadMessages?.length > 0
                                    ? (
                                        <Tag icon={<MessageOutlined />} color="processing" className={`font-bold`}>{_t('orders.titles.incomingMessage')}</Tag>
                                    )
                                    :
                                    <StatusLabels value={value} />
                                }
                            </div>
                        }
                        width={100}
                        filters={
                            [
                                { text: _t("orders.fields.statusValues.submitted"), value: "submitted" },
                                { text: _t("orders.fields.statusValues.ongoing"), value: "ongoing" },
                                { text: _t("orders.fields.statusValues.completed"), value: "completed" },
                                { text: _t("orders.fields.statusValues.canceled"), value: "canceled" },
                            ]
                        }
                        onFilter={(value: any, record: any) => record.status === value}
                    />
                    <Table.Column
                        title={_t("table.actions")}
                        dataIndex="actions"
                        render={(_, record: BaseRecord) => (
                            <Space>
                                <EditButton
                                    resource="orders"
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    hidden
                                />
                                {user?.role.id === UserRole.admin && (
                                    <>
                                        <ShowButton
                                            resource="orders"
                                            size="small"
                                            recordItemId={record.id}
                                        />
                                        {!(["canceled"].includes(record.status)) && (
                                            <DeleteButton
                                                resource="orders"
                                                size="small"
                                                recordItemId={record.id}
                                                onSuccess={() => { tableQueryResult.refetch() }}
                                            >
                                                {_t("buttons.cancel")}
                                            </DeleteButton>
                                        )}
                                    </>
                                )}
                                {user?.role.id === UserRole.user && (
                                    <Button
                                        icon={<EyeOutlined />}
                                        size="small"
                                        href={`/accounts/${account.id}/orders/show/${record.id}`}
                                    >
                                        {_t("buttons.show")}
                                    </Button>
                                )}
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
    );
};

interface IAccount {
    id: number;
    name: string;
}

