import React, {useEffect, useMemo, useState} from "react";
import {
    IResourceComponentsProps,
    useApiUrl,
    useCreate,
    useCreateMany,
    useDelete,
    useGetIdentity,
    useInvalidate,
    useNotification,
    useParsed,
    useShow,
    useTranslate,
    useUpdate, useUpdateMany,
} from "@refinedev/core";
import {
    Show,
    NumberField,
    TagField,
    TextField,
    MarkdownField,
    DateField,
    useTable,
    DeleteButton,
    Breadcrumb, useSelect,
} from "@refinedev/antd";
import {
    Button,
    Card,
    Col,
    Divider,
    Dropdown,
    Flex,
    Progress,
    Row,
    Space,
    Table,
    Tabs,
    TabsProps,
    Typography,
    Upload,
    UploadProps,
    message,
    theme, Input, Select
} from "antd";
import {concatValues, flattenData} from "../../shared/utils";
import {StatusLabels} from "./components/statusLabels";
import {
    ArrowLeftOutlined, CloseCircleFilled, CloseCircleOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    MedicineBoxOutlined,
    UploadOutlined
} from "@ant-design/icons";
import {TOKEN_KEY} from "../../authProvider";
import {OrderStatus} from "../../models/order";
import {UserRole} from "../../models/role";
import {Messages} from "./components/messages";
import {IMessage, exampleMessages} from "../../models/message";
import {NotFound} from "../../components/errors";
import {CustomerBreadcrumb} from "./components/customerBreadcrumb";
import {CustomerBackButton} from "./components/customerBackButton";
import {useAdmin} from "../../hooks/auth";
import {useSearchParams} from "react-router-dom";
import {generateIniFile} from "./components/iniFile";
import {saveAs} from 'file-saver';
import {useDebouncedCallback} from "use-debounce";

const {Title} = Typography;

const CardTitle = ({title, bg = 'bg-white'}) => {
    return (
        <Flex justify="left" align="center" style={{marginTop: '-36px'}} className="mb-4">
            <div className={`text-center ${bg} px-4 text-md font-semibold`}>
                {title}
            </div>
        </Flex>
    );
}

const FieldListItem: React.FC<{ name: string, value: string }> = ({name, value}) => {
    const {token} = theme.useToken();
    return (
        <Row className="min-w-96">
            <Col span={12}>
                <Space>
                    <TextField value={name} style={{color: token.colorPrimaryText}}/>
                    <span style={{color: token.colorPrimaryText}}>: </span>
                </Space>
            </Col>
            <Col span={12}>
                <TextField value={value}/>
            </Col>
        </Row>
    );
}

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
    const _t = useTranslate();
    const {token} = theme.useToken();
    const {queryResult, setShowId} = useShow({
        resource: 'orders',
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const {data, isLoading, error} = queryResult;
    const [formData, setFormData] = React.useState({} as any);
    const [formValues, setFormValues] = React.useState([] as any[]);
    const {Text} = Typography;
    const apiUrl = useApiUrl();
    const authToken = localStorage.getItem(TOKEN_KEY);
    const {mutate} = useCreate();
    const {mutate: deleteResource} = useDelete();
    const {mutate: update} = useUpdate();
    const {mutate: updateMany} = useUpdateMany();
    const {mutate: mutateMany} = useCreateMany();
    const {open} = useNotification();
    const invalidate = useInvalidate();
    const [record, setRecord] = React.useState(data?.data as any);
    const [attachments, setAttachments] = React.useState([]);
    const [resultAttachments, setResultAttachments] = React.useState([]);
    const [messages, setMessages] = React.useState(data?.data?.messages as IMessage[]);
    const {data: user} = useGetIdentity();
    const {id} = useParsed();
    const [messageUploadedFiles, setMessageUploadedFiles] = useState([]);
    const isAdmin = useAdmin();
    const [messageFileList, setMessageFileList] = useState([]);
    const [activeKey, setActiveKey] = useState(searchParams.get('view') == 'messages' ? '2' : '1');
    const [serviceComments, setServiceComments] = useState(record?.serviceComments);
    const [productOptions, setProductOptions] = useState([]);
    const [isPrepaid, setIsPrepaid] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [orderStatus, setOrderStatus] = useState(record?.status);

    useEffect(() => {
        if (record?.account) {
            setIsPrepaid(record?.account?.billingMode === "prepaid");
        }
    }, [record]);

    useEffect(() => {
        // Force show id as Refine can't infer it from the URL
        setShowId(id);
    });

    const {selectProps: productList} = useSelect({
        resource: "products",
        optionLabel: "id",
        optionValue: "price",
    });

    const {selectProps: productSelectProps, queryResult: productQueryResult} = useSelect({
        resource: "products",
        /*optionLabel: (option) => {
            if (isPrepaid) {
                return `${option.name} - ${option.price} ${_t("credits.credits")}`;
            } else {
                return `${option.name}`;
            }
        },*/
        optionValue: "id",
        searchField: "name",
        sorters: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });

    useEffect(() => {
        if (productQueryResult.data && productOptions.length === 0) {
            let groupedOptions = productQueryResult.data?.data.reduce((groups, item) => {
                let group = `${item.group}`; // replace with your actual group property
                if (group == "null") {
                    group = ""
                }
                if (!groups[group]) {
                    groups[group] = {label: group, options: []};
                }
                groups[group].options.push({
                    label: isPrepaid ? `${item.name} - ${item.price} ${_t("credits.credits")}` : `${item.name}`,
                    value: item.id,
                });
                return groups;
            }, {});
            //console.log("Grouped options", JSON.stringify(groupedOptions));
            const sortedGroupedOptions = Object.entries(groupedOptions)
                .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                .reduce((sortedObj, [key, value]) => {
                    sortedObj[key] = value;
                    return sortedObj;
                }, {});
            //console.log("sortedGroupedOptions ", JSON.stringify(sortedGroupedOptions));
            // Use sortedGroupedOptions instead of groupedOptions
            console.log("Product options", Object.values(sortedGroupedOptions));
            setProductOptions(Object.values(sortedGroupedOptions));
        }
    }, [productQueryResult, isPrepaid]);


    const field = (name: string): any => {
        return formValues.find((field: any) => field.name === name);
    }

    const ProgressComp = ({percent}) => {
        return (
            <Space>
                <Progress type="circle" percent={percent} size={40}/>
                Transfert en cours
            </Space>
        );
    }

    const downloadIniFile = () => {
        console.log("Form values", formValues);
        const data = formValues.reduce((obj, field, index) => {
            obj[field.name] = field.value;
            return obj;
        }, {});
        console.log("Form values", data);
        const params = {...data, account: record.account.name};
        const out = generateIniFile(params);
        const blob = new Blob([out], {type: 'text/plain;charset=utf-8;'});
        saveAs(blob, 'winols.ini');
    }

    const downloadIniFileAndClientFiles = () => {
        downloadIniFile();
        console.log("Downloading client files");
        const clientFiles = record.attachments.filter((attachment) => attachment.direction == 'in');
        clientFiles.map((attachment) => {
            window.open(attachment.file.path, "_blank");
        });

    }

    useEffect(() => {
        if (record?.payload !== undefined && record?.payload !== null && record?.payload.trim() !== '') {
            const formData = JSON.parse(record.payload);
            const vals = flattenData(formData);
            setFormValues(vals);
            setFormData(formData);
            console.log("Record data", record);
            if (record.status == OrderStatus.submitted && isAdmin) {
                console.log("User Admin, Change Order status to in progress");
                update({
                    resource: 'orders',
                    values: {status: OrderStatus.ongoing},
                    id: record.id,
                }, {
                    onSuccess: () => {
                        console.log("Status changed to in progress");
                        //invalidate({ resource: 'orders', invalidates: ["detail"], id: record?.id });
                    }
                });
            }
            //console.log(vals);
            /*open?.({
                message: (<ProgressComp percent={0} />),
                key: "unique-id",
                duration: 0,
            });*/

        }
        setServiceComments(record?.serviceComments);

        if (record?.products?.length > 0) {
            console.log("Selected products", Array.from(record?.products?.map((p) => p.id)));
            setSelectedProducts(record?.products?.map((p) => p.id));
        }
    }, [record]);

    function hasUnreadMessages(order) {
        return order?.messages?.filter(
            (message: any) => message.isRead == false //&& message.sender.id != user?.id
        ).length > 0;
    }

    useEffect(() => {
        if (data?.data) {
            const record = data.data;
            setRecord(record);
            setAttachments(record.attachments.filter((attachment) => attachment.direction == 'in'));
            setResultAttachments(record.attachments.filter((attachment) => attachment.direction == 'out'));
            // Sort messages by createdAt
            const sortedMsg = record.messages.sort((a, b) => {
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            });
            setMessages(sortedMsg);
            setOrderStatus(record.status);
            console.log('Record messages', record.messages);
            //console.log("Messages not read", record.messages?.filter(i => !i.isRead));
            if (hasUnreadMessages(record) && record.status == OrderStatus.completed) {
                //setOrderStatus('sav');
            }
            console.log("Attachments", record.attachments);
            console.log("Messages", record.messages);
            if (isAdmin) {
                record.messages
                    .filter((message: any) => message.isRead == false)
                    .map((message: any) => {
                        console.log("Mark message as read", message);
                        update({
                            resource: 'messages',
                            values: {isRead: true},
                            id: message.id,
                        }, {
                            onSuccess: () => {
                                console.log("Message marked as read");
                            }
                        });
                    });
            }
        }
    }, [data]);

    function markMessagesRead() {
        updateMany({
            resource: 'messages',
            values: {isRead: true},
            ids: record?.messages?.map((m) => m.id),
        }, {
            onSuccess: () => {
                console.log("Messages marked as read");
            }
        });

        /*record?.messages
            .filter((message: any) => message.isRead == false)
            .map((message: any) => {
                console.log("Mark message as read", message);
                update({
                    resource: 'messages',
                    values: {isRead: true},
                    id: message.id,
                }, {
                    onSuccess: (data) => {
                        console.log("Message marked as read", data.data);
                    }
                });
            });*/
    }

    const fileUploadProps: UploadProps = {
        name: 'file',
        action: apiUrl + '/files/upload',
        method: 'POST',
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                //message.success(`${info.file.name} file uploaded successfully`);
                console.log("File uploaded successfully");
                console.log(info.file);
                const res = info.file;
                if (res) {
                    console.log("File uploaded successfully", res.response.file);
                    //res.file.path
                    const metadata = {
                        type: res.type,
                        size: res.size,
                    };
                    const attachment = {
                        name: res.name,
                        fileId: res.response.file.id,
                        metadata: JSON.stringify(metadata),
                        direction: 'out',
                        orderId: `${record.id}`,
                    };
                    mutate({
                        resource: 'attachments',
                        values: attachment
                    }, {
                        onSuccess: () => {
                            console.log("Attachment created successfully");
                            console.log("Mark order as completed");
                            update({
                                resource: 'orders',
                                values: {status: OrderStatus.completed},
                                id: record.id,
                            }, {
                                onSuccess: () => {
                                    console.log("Status changed to completed");
                                    markMessagesRead();
                                    invalidate({resource: 'orders', invalidates: ["detail"], id: record?.id});
                                },
                            });
                        }
                    });
                }
            } else if (info.file.status === 'error') {
                //message.error(`${info.file.name} file upload failed.`);
                if (info.file.error.status == 401) {
                    // Unauthorized, try refreshing token
                }
                console.log("File upload failed",);
            }
        },
    };

    const messageFileUploadProps: UploadProps = {
        name: 'file',
        action: apiUrl + '/files/upload',
        method: 'POST',
        onChange(info) {

            setMessageFileList([...info.fileList]);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                //message.success(`${info.file.name} file uploaded successfully`);
                console.log("File uploaded successfully");
                console.log(info.file);
                const res = info.file;
                if (res) {
                    console.log("File uploaded successfully", res.response.file);
                    //res.file.path
                    const newFile = {
                        name: res.name,
                        fileId: res.response.file.id,
                        metadata: JSON.stringify({
                            type: res.type,
                            size: res.size
                        })
                    }
                    setMessageUploadedFiles([...messageUploadedFiles, newFile]);
                }
            } else if (info.file.status === 'error') {
                //message.error(`${info.file.name} file upload failed.`);
                if (info.file.error.status == 401) {
                    // Unauthorized, try refreshing token

                }
                console.log("File upload failed",);
            }
        },
    };

    const sendMessage = (message: string) => {
        console.log("Sending message", message);
        const newMessage = {
            content: message,
            orderId: `${record.id}`,
            senderId: `${user?.id}`,
            isRead: isAdmin,
        };

        console.log("New message", newMessage);
        mutate({
            resource: 'messages',
            values: newMessage
        }, {
            onSuccess: (data) => {
                if (!isAdmin && record?.status == OrderStatus.completed) {
                    markMessagesRead(); // Force messages as read
                    // Set order status to sav
                    update({
                        resource: 'orders',
                        values: {status: OrderStatus.sav},
                        id: record.id,
                    }, {
                        onSuccess: () => {
                            console.log("Status changed to SAV");
                            invalidate({resource: 'orders', invalidates: ["detail"], id: record?.id});
                        }
                    });
                }
                console.log("Message sent successfully");
                const message = data.data;
                console.log("Message", message);
                const attachmentList: any[] = messageUploadedFiles.map((file) => {
                    return {
                        name: file.name,
                        fileId: file.fileId,
                        metadata: file.metadata,
                        messageId: `${message.id}`,
                        direction: isAdmin ? 'out' : 'in',
                    };
                });

                if (attachmentList.length > 0) {
                    mutateMany({
                        resource: 'attachments',
                        values: attachmentList,
                    }, {
                        onSuccess: () => {
                            console.log("Attachment created successfully");
                            setMessageUploadedFiles([]);
                            invalidate({resource: 'orders', invalidates: ["detail"], id: record?.id});
                            setMessageFileList([]);
                        },
                        onError: () => {
                            console.log("Attachment creation failed");
                            open?.({
                                message: _t("orders.errors.createAttachment"),
                                type: "error",
                                key: "unique-id",
                                duration: 0,
                            });
                        }
                    });
                } else {
                    invalidate({resource: 'orders', invalidates: ["detail"], id: record?.id});
                }
            }
        });
    }

    const deleteResultAttachment = (attachment) => {
        console.log("Deleting attachment", attachment);
        deleteResource({
            resource: 'attachments',
            id: attachment.id,
            values: attachment,
        }, {
            onSuccess: () => {
                console.log("Attachment deleted successfully");
                invalidate({resource: 'orders', invalidates: ["detail"], id: record?.id});
                setResultAttachments(resultAttachments.filter((a) => a.id != attachment.id));
                console.log("Res Attachments", resultAttachments);
            }
        });
    }

    const updateProducts = (products: any[]) => {
        console.log("Updating products", products);
        update({
            resource: 'orders',
            values: {
                productIds: products.map((v: number) => v.toString()),
            },
            id: record.id,
        }, {
            onSuccess: () => {
                console.log("Products updated");
                invalidate({resource: 'orders', invalidates: ["detail"], id: record?.id});
            }
        });
    }

    const handleProductChange = (values: any[]) => {
        console.log("Product selected", values);
        updateProducts(values);
        const prods = productList?.options?.map((p: any) => {
            return {
                price: p.value,
                id: p.label,
            };
        });
        console.log("Productz", prods);
        const total = values.reduce((acc, curr) => {
            const product = prods.find((p: any) => p.id === curr);
            console.log("Productz2", product);
            return product.price ? acc + product.price : acc;
        }, 0);
        //setTotalAmount(total);
    }

    if (error) {
        return <NotFound/>;
    }

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Demande',
            children: (
                <Row gutter={[{xs: 8, sm: 16, md: 24}, 24]} className="mt-2">
                    <Col xs={24} md={24}>
                        {isAdmin && (
                            <Flex gap={'small'}>
                                <Button
                                    icon={<DownloadOutlined/>}
                                    onClick={downloadIniFile}
                                    size="small"
                                    type="primary"
                                >
                                    {_t("orders.titles.downloadIni")}
                                </Button>
                                <Button
                                    icon={<DownloadOutlined/>}
                                    onClick={downloadIniFileAndClientFiles}
                                    size="small"
                                    type="primary"
                                >
                                    {_t("orders.titles.downloadIniAndCustomerFiles")}
                                </Button>
                            </Flex>
                        )}
                        <Row gutter={[{xs: 8, sm: 16, md: 24}, 24]} className="mt-6">
                            <Col xs={24} sm={12}>
                                <Card className="min-h-52">
                                    <CardTitle title={_t("orders.fields.caracteristics")}/>
                                    {['make', 'power', 'mileage', 'model', 'gearbox', 'engine', 'year']
                                        .map((name, index) => (
                                            <FieldListItem key={index} name={field(name)?.label}
                                                           value={field(name)?.value}/>
                                        ))}
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="min-h-52">
                                    <CardTitle title={_t("orders.fields.identification")}/>
                                    {['owner', 'email', 'immatriculation', 'vin', 'hw', 'sw']
                                        .map((name, index) => (
                                            <FieldListItem key={index} name={field(name)?.label}
                                                           value={field(name)?.value}/>
                                        ))}
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="min-h-32">
                                    <CardTitle title={_t("orders.fields.readingMethod")}/>
                                    {['calculator', 'prepared', 'method']
                                        .map((name, index) => (
                                            <FieldListItem key={index} name={field(name)?.label}
                                                           value={field(name)?.value}/>
                                        ))}
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="min-h-32">
                                    <CardTitle title={_t("orders.fields.reprogramming")}/>
                                    <div className="hidden">
                                        <FieldListItem
                                            name={_t('products.products')}
                                            value={concatValues(record?.products, 'name')}
                                        />
                                    </div>
                                    <Row className="min-w-96">
                                        <Col span={12}>
                                            <Space>
                                                <TextField value={"Produits"} style={{color: token.colorPrimaryText}}/>
                                                <span style={{color: token.colorPrimaryText}}>: </span>
                                            </Space>
                                        </Col>
                                        <Col span={12}>
                                            <Select
                                                placeholder={_t('orders.titles.selectProducts')}
                                                className="w-full"
                                                mode="multiple"
                                                allowClear
                                                onChange={handleProductChange}
                                                options={productOptions}
                                                value={selectedProducts}
                                                disabled={!isAdmin}
                                            />
                                        </Col>
                                    </Row>
                                    <FieldListItem name={_t('orders.fields.comments')} value={record?.comments}/>
                                </Card>
                            </Col>
                        </Row>

                        <Row gutter={[{xs: 8, sm: 16, md: 24}, 16]} className="mt-6">
                            <Col xs={24}>
                                <Card>
                                    <CardTitle title={_t("orders.fields.downloads")}/>

                                    <Table dataSource={attachments} rowKey="id" size="small">
                                        <Table.Column
                                            dataIndex="id"
                                            title={_t("attachments.fields.id")}
                                            sorter={{multiple: 2}}
                                            width={60}
                                        />
                                        <Table.Column
                                            dataIndex="createdAt"
                                            title={_t("attachments.fields.createdAt")}
                                            render={(value: any) => <DateField value={value}/>}
                                            width={180}
                                        />
                                        <Table.Column
                                            dataIndex="name"
                                            title={_t("attachments.fields.name")}
                                        />
                                        <Table.Column
                                            title={_t("table.actions")}
                                            dataIndex="actions"
                                            width={180}
                                            render={(_, attachment) => (
                                                <Space>
                                                    <Button
                                                        type="primary"
                                                        icon={<DownloadOutlined/>}
                                                        size="small"
                                                        target="_blank"
                                                        href={attachment.file.path}
                                                    >
                                                        {_t("attachments.titles.download")}
                                                    </Button>
                                                    {isAdmin && (
                                                        <DeleteButton
                                                            resource="attachments"
                                                            recordItemId={attachment?.id}
                                                            size="small"
                                                            hideText={false}
                                                            onSuccess={() => {
                                                                invalidate({
                                                                    resource: 'orders',
                                                                    invalidates: ["detail"],
                                                                    id: record?.id
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                </Space>
                                            )}
                                        />
                                    </Table>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ),
        },
        {
            key: '2',
            label: _t("orders.titles.aftersales"),
            children: (
                <Flex justify="center" align="center" className="mt-6">
                    <div className="sm:w-100 md:w-2/3">
                        <Messages
                            messages={messages}
                            sendMessage={sendMessage}
                            fileUploadProps={messageFileUploadProps}
                            fileList={messageFileList}
                        />
                    </div>
                </Flex>
            ),
        }
    ]

    const updateServiceComments = (comment: string) => {
        console.log("Updating service comments", comment);
        update({
            resource: 'orders',
            values: {serviceComments: comment},
            id: record.id,
        }, {
            onSuccess: () => {
                console.log("Service comments updated");
            }
        });
    }
    const debouncedUpdateServiceComments = useDebouncedCallback(updateServiceComments, 500);

    const onServiceCommentsChange = (e) => {
        const comment = e.target.value;
        //console.log("Service comments changed", comment);
        setServiceComments(comment);
        debouncedUpdateServiceComments(comment);
    };

    const openSAV = () => {
        console.log("Opening SAV ticket");
        update({
            resource: 'orders',
            values: {status: OrderStatus.sav},
            id: record.id,
        }, {
            onSuccess: () => {
                console.log("Status changed to SAV");
                invalidate({resource: 'orders', invalidates: ["detail"], id: record?.id});
            }
        });
    }

    const closeSAV = () => {
        console.log("Closing SAV ticket");
        update({
            resource: 'orders',
            values: {status: OrderStatus.completed},
            id: record.id,
        }, {
            onSuccess: () => {
                console.log("Status changed to completed");
                invalidate({resource: 'orders', invalidates: ["detail"], id: record?.id});
            }
        });
    }

    return (
        <Show
            isLoading={isLoading}
            resource="orders"
            canDelete={false}
            canEdit={false}
            title={<>
                {user?.role?.id == UserRole.user && (
                    <CustomerBackButton/>
                )}
                {_t("orders.titles.show")}
            </>}
            breadcrumb={
                <>
                    {isAdmin && (
                        <Breadcrumb/>
                    )}
                    {user?.role?.id == UserRole.user && (
                        <CustomerBreadcrumb title={_t('actions.show')}/>
                    )}
                </>
            }
            headerButtons={({defaultButtons}) => (
                <>
                    {isAdmin &&
                        defaultButtons
                    }
                    {(isAdmin && record?.status == "completed") && (
                        <>
                            <Button type="primary" icon={<MedicineBoxOutlined />} onClick={openSAV}>
                                Ticket SAV
                            </Button>
                        </>
                    )}
                    {(isAdmin && record?.status == "sav") && (
                        <>
                            <Button type="primary" icon={<CloseCircleOutlined />} onClick={closeSAV}>
                                Clôturer Ticket
                            </Button>
                        </>
                    )}
                </>
            )}
        >
            <Flex justify="space-between" align="flex-start">
                <Flex vertical>
                    <Space className="font-bold">
                        {_t("orders.fields.request")} # <NumberField value={record?.id ?? ""}/>
                    </Space>
                    <Space className="font-bold">
                        {_t('orders.fields.account')} : <TextField value={record?.account?.name}/>
                    </Space>
                </Flex>
                <Flex justify="center" align="center" vertical className="mb-8">
                    <Title level={4} style={{color: token.colorPrimaryText}}>
                        {field('make')?.value} {field('model')?.value} {field('engine')?.value}
                    </Title>

                    <Space>
                        <Text>
                            {_t("orders.fields.createdAt")}
                        </Text>
                        <DateField value={record?.createdAt} format="DD/MM/YYYY HH:mm"/>
                    </Space>
                </Flex>
                <StatusLabels value={orderStatus}/>

            </Flex>
            {(isAdmin || record?.status == "completed") && (
                <>
                    <Card className="bg-blue-50" size="default">
                        <Typography.Title level={5} style={{color: token.colorPrimaryText}}>
                            {_t("orders.titles.downloadFinalFile")}
                        </Typography.Title>
                        <Space size={"middle"} className="w-full">
                            {resultAttachments.map((attachment) => (
                                <>
                                    {isAdmin && (
                                        <Dropdown.Button
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                                window.open(attachment.file.path, "_blank")
                                            }}
                                            menu={{
                                                items: isAdmin ? [{key: '1', label: _t('buttons.delete')}] : [],
                                                onClick: () => {
                                                    deleteResultAttachment(attachment);
                                                }
                                            }}
                                        >
                                            {attachment.name}
                                        </Dropdown.Button>
                                    )}
                                    {!isAdmin && (
                                        <Button
                                            type="primary"
                                            size="small"
                                            icon={<DownloadOutlined/>}
                                            onClick={() => {
                                                window.open(attachment.file.path, "_blank")
                                            }}
                                        >
                                            {attachment.name}
                                        </Button>
                                    )}
                                </>
                            ))}
                            {isAdmin && (
                                <Upload
                                    {...fileUploadProps}
                                    showUploadList={true}
                                >
                                    <Button icon={<UploadOutlined/>} size="small" type="primary">
                                        {_t('orders.titles.upload')}
                                    </Button>
                                </Upload>
                            )}
                        </Space>
                        <Space className="mt-4">
                            {isAdmin && (
                                <>
                                    Commentaires :
                                    <Input
                                        className="w-96"
                                        value={serviceComments}
                                        onChange={onServiceCommentsChange}
                                    />
                                </>
                            )}
                            {!isAdmin && record?.serviceComments && (
                                <>
                                    <span className="font-bold">Commentaires</span> : {record?.serviceComments}
                                </>
                            )}
                        </Space>
                    </Card>
                </>
            )}

            <Divider/>

            <Tabs defaultActiveKey={activeKey} type="card" centered items={items}/>

        </Show>
    );
};

